import React from 'react'

const Behavioral = ({ info }) => {
    
    return (
        <div>
            <div className="mt-2 max-w-full divide-y">
                <div className="py-2">
                    <h3 className="text-1xl font-bold dark:text-gray-200">Contact Information</h3>
                    <hr />
                </div>
            </div>
            <div className="py-2 items-right">
                <div className="mt-2 max-w-full">
                    <div className="grid gap-6 md:grid-cols-2">
                        {info?.full_name && info.full_name !== "null null" && 
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Full Name</span>
                            <input type="text" value={info?.full_name} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white" disabled />
                        </label>}
                        {info?.email && info.email !== "null null" && 
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Email</span>
                            <input type="text" value={info?.email} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white" disabled />
                        </label>}
                        {info?.phone && info.phone !== "null null" && 
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Phone Number</span>
                            <input type="text" value={info?.phone} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white" disabled />
                        </label>}
                        {info?.gender && info.gender !== "null null" && 
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Gender</span>
                            <input type="text" value={info?.gender} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white" disabled />
                        </label>}
                        {info?.bvn && info.bvn !== "null null" && 
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">BVN</span>
                            <input type="text" value={info?.bvn} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white" disabled />
                        </label>}
                        {info?.dob && info.dob !== "null null" && 
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">D.O.B</span>
                            <input type="text" value={info?.dob} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white" disabled />
                        </label>}
                        {info?.state_of_origin && info.state_of_origin !== "null null" && 
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">State of Origin</span>
                            <input type="text" value={info?.state_of_origin} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white" disabled />
                        </label>}
                        {info?.lga_of_origin && info.lga_of_origin !== "null null" && 
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">LGA of Origin</span>
                            <input type="text" value={info?.lga_of_origin} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white" disabled />
                        </label>}
                        {info?.marital_status && info.marital_status !== "null null" && 
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Marital Status</span>
                            <input type="text" value={info?.marital_status} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white" disabled />
                        </label>}
                        {info?.address_line1 && info.address_line1 !== "null null" && 
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Address Line 1</span>
                            <input type="text" value={info?.address_line1} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white" disabled />
                        </label>}
                        {info?.address_line2 && info.address_line2 !== "null null" &&
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Address Line 2</span>
                            <input type="text" value={info?.address_line2} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white" disabled />
                        </label>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Behavioral

export function parseAPIFormatToDate(dateString) {
  const regex =
    /(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})([+-])(\d{2})(\d{2})/;
  const match = dateString.match(regex);

  if (!match) {
    throw new Error("Invalid date format");
  }

  const [
    ,
    day,
    month,
    year,
    hours,
    minutes,
    seconds,
    offsetSign,
    offsetHours,
    offsetMinutes,
  ] = match;

  const utcDate = new Date(
    Date.UTC(
      Number(year),
      Number(month) - 1,
      Number(day),
      Number(hours),
      Number(minutes),
      Number(seconds)
    )
  );
  const offset =
    (offsetSign === "+" ? 1 : -1) *
    (Number(offsetHours) * 60 + Number(offsetMinutes));

  return new Date(utcDate.getTime() + offset * 60 * 1000);
}

export function formatDate(date) {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return date.toLocaleDateString(undefined, options);
}

export const getMonth = (date) => {
  const options = { month: 'long' }; // Use 'short' for abbreviated month names
  return date.toLocaleDateString(undefined, options);
};

export const getDifferenceBetweenDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const oneDay = 24 * 60 * 60 * 1000;
  const daysDifference = Math.round((end - start) / oneDay);

  if (daysDifference < 30) {
    return `${daysDifference} day${daysDifference !== 1 ? 's' : ''}`;
  } else {
    const yearsDifference = end.getFullYear() - start.getFullYear();
    const monthsDifference = end.getMonth() - start.getMonth();
    const totalMonthsDifference = yearsDifference * 12 + monthsDifference;
    return `${totalMonthsDifference} month${totalMonthsDifference !== 1 ? 's' : ''}`;
  }
};
import React from 'react'
//import { Link } from 'react-router-dom';
//import { formatter } from '../../utils/requestMethods';
import { BsCurrencyDollar } from 'react-icons/bs';
import { getDifferenceBetweenDates } from '../../utils/dateFormat';

const Behavioral = ({ mls }) => {
    //console.log(mls?.behavioral);
    const currentColor = "#bb6bd9";
    const currentBg = "rgba(187, 107, 217, 0.33)";
    return (
        <div>
            <div className="flex flex-wrap lg:flex-nowrap justify-center">
                <div className="bg-gray dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl wd-41 md:wd-41 p-8 pt-9 m-1" style={{ border: '1px solid #ebecf0' }}>
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-xl font-bold">Gambling / Betting:: <span className="text-gray-500">NO</span></p>
                            <p className="text-lg">Gambling Rate (% of inflow): <span className="text-gray-400">2.00%</span></p>
                            <p className="text-lg">First Day in Statement: <span className="text-gray-400">{mls?.cash_flow?.opening_date}</span></p>
                            <p className="text-lg">Last Day in Statement: <span className="text-gray-400">{mls?.cash_flow?.closing_date}</span></p>
                            <p className="text-lg"> Statement Duration: <span className="text-gray-400">{getDifferenceBetweenDates(mls?.cash_flow?.opening_date, mls?.cash_flow?.closing_date)}</span></p>
                        </div>
                        <button type="button" style={{ backgroundColor: currentColor }} className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4">
                            <BsCurrencyDollar />
                        </button>
                    </div>
                </div>
                {/* start cards one */}
                <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full md:w-60 p-4 pt-9 m-1" style={{ border: '1px solid #ebecf0', width: '18rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.behavioral?.top_incoming_transfer_account}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Top Incoming Transfer Account</p>
                </div>
                {/* end cards one */}
                {/* start cards two */}
                <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full md:w-60 p-4 pt-9 m-1" style={{ border: '1px solid #ebecf0', width: '18rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.behavioral?.loan_repayments}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Loan Repayments</p>
                </div>
            </div>
            {/* breakdown info cards */}
            <div className="flex m-3 flex-wrap gap-2 items-left">
                {/* start cards one */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.behavioral?.loan_inflow_rate} %</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Loan Inflow Rate</p>
                </div>
                {/* end cards one */}
                {/* start cards two */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.behavioral?.account_sweep}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Account Sweep</p>
                </div>
                {/* end cards two */}
                {/* start cards three */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.behavioral?.loan_repayment_inflow_rate} %</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Loan Repayment Inflow Rate</p>
                </div>
                {/* end cards three */}
                {/* start cards four */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">No top recipient found
                            {/*mls?.behavioral?.on_top_recipient_account_found*/}
                        </span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Top Transfer Recipient Acc..</p>
                </div>
                {/* end cards four */}
                {/* start cards one */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">POSITIVE (+) {mls?.behavioral?.inflow_outflow_rate}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Inflow Outflow Rate</p>
                </div>
                {/* end cards one */}
                {/* start cards two */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">₦ {mls?.behavioral?.loan_amount}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Loan Amount</p>
                </div>
                {/* end cards two */}
            </div>
        </div>
    )
}

export default Behavioral

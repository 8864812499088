import React, { useState } from 'react';
import axios from "axios";
//import PinInput from 'react-pin-input';
import { ButtonLoading } from '../../components';
import './css/default.css'
import './css/style.css'
import logo from "../../data/logo/logo.png";
import circle from './img/circle.png';
import dot from './img/dot.png';
import signup from './img/sign-up.png';
import zigzag from './img/zigzag.png';
import welcome from './img/welcome2.png';

const Welcome = ({ history }) => {
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const verifyHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        //console.log(code);
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.get(
                `https://acceede.herokuapp.com/auth/verify?tkn=${code}`,
                config
            );
            //console.log(data.classes);
            history.push("/signin");
        } catch (error) {
            //console.log(error.response.data);
            setError(error.response.data);
            setTimeout(() => {
                setError("");
            }, 5000);
            setLoading(false);
        }
        setLoading(false);
    };

    return (
        <main>
            <section className="signup__area po-rel-z1 p-t-40 pb-100">
                <div className="sign__shape">
                    <img className="circle" src={circle} alt="" />
                    <img className="zigzag" src={zigzag} alt="" />
                    <img className="dot" src={dot} alt="" />
                    <img className="bg" src={signup} alt="" />
                </div>
                <div className="row">
                    <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
                        <div className="section__title-wrapper text-center m-b-40">
                            <img src={logo} alt="logo" className="mx-auto" width="250" />
                        </div>
                    </div>
                </div>
                
                <div className="max-w-xl mx-auto mt-3 divide-y p-3">
                    {error &&
                        <div className="alert alert-warning d-flex align-items-center" role="alert">
                            <i className="fal fa-lock pr-10"></i>
                            <div>
                                {error}
                            </div>
                        </div>
                    }
                    <div className="sign__wrapper bg-white mx-auto">
                        <div className="sign__header mb-35">
                            <div className="sign__in text-center">
                                <img src={welcome} alt="logo" className="mx-auto" width="" />
                            </div>
                        </div>
                        <div className="sign__form px-2 text-center">
                            <h3 className="text-2xl font-bold text-center">Welcome onboard!</h3>
                            <p className="mb-25">Check your mail inbox for a verification mail from us and follow the instructions.</p>

                            <div className="sign__input-wrapper mb-25">
                                <div className="col-md-4 sign__input">
                                    {/*<PinInput length={6} focus type="custom" inputMode="numeric" onChange={(value, index) => setCode(value, index)} />*/}
                                    <input type="text" placeholder="Verification Code" onChange={(e) => setCode(e.target.value)} required />
                                </div>
                            </div>
                            
                            {loading ?
                                (
                                    <ButtonLoading styles="e-btn w-100 auth-btn" />
                                ) : (
                                    <button onClick={verifyHandler} className="e-btn w-100 auth-btn"> Verify</button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
export default Welcome;
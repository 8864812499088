import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { userRequest, formatter } from '../utils/requestMethods';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { ImSpinner } from "react-icons/im";

const ViewCustomer = ({ history }) => {
    const { user, school, currentColor } = useStateContext();
    //const schoolId = school?._id
    const location = useLocation();
    const schoolId = location.pathname.split('/')[3];
    console.log(schoolId);
    const [transaction, setTransaction] = useState({});
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const goBack = () => {
        // Go back to the previous page
        history.goBack();
    };

    useEffect(() => {
        const fetchClasses = async () => {
          try {
            setLoading(true);
            const { data } = await userRequest.get(`/schools/${schoolId}`);
            //console.log(data);
            setTransaction(data);
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
          }
        };
        fetchClasses();
    }, [schoolId, refresh]);

    const kycProgressMapping = {
        0: 'Not Started',
        1: 'Details Submitted',
        2: 'Reviewed',
        3: 'Anchor Account Created',
        4: 'Validation In Progress',
        5: 'Awaiting Docs',
        6: 'KYC Validation Failed',
        7: 'KYC Validated',
        8: 'Deposit Account Created'
    };

    const verifyHandler = async (userId) => {
        try {
          const { data } = await userRequest.post(`/schools/${userId}/kyc-verify`, {
            status: "approved"
          });
          console.log(data);
          setRefresh(!refresh);
          setSuccess("KYC details validated successfully!");
          setTimeout(() => {
            setSuccess(false);
        }, 1000);
          //setRefresh(data._id);
        } catch (error) {
          console.log(error);
          if (error.response.data) {
            setError("Error! " + error.response.data);
            setTimeout(() => {
                setError("");
            }, 2000);
          } else {
            setError("Something went wrong, try again later!");
            setTimeout(() => {
                setError("");
            }, 2000);
          }
        }
      };
    
      const anchorHandler = async (userId) => {
        try {
          const { data } = await userRequest.post(`/schools/${userId}/create-account`, {});
          console.log(data);
          setRefresh(!refresh);
          setSuccess("Anchor customer Account created successfully!");
          setTimeout(() => {
            setSuccess(false);
        }, 1000);
          //setRefresh(data._id);
        } catch (error) {
          console.log(error);
          if (error.response.data) {
            setError("Error! " + error.response.data);
            setTimeout(() => {
                setError("");
            }, 2000);
          } else {
            setError("Something went wrong, try again later!");
            setTimeout(() => {
                setError("");
            }, 2000);
          }
        }
      };
    
      const validateHandler = async (userId) => {
        try {
          const { data } = await userRequest.post(`/schools/${userId}/validate-kyc`, {});
          console.log(data);
          setSuccess("KYC Validation successful!");
          setTimeout(() => {
            setSuccess(false);
        }, 1000);
          //setRefresh(data._id);
        } catch (error) {
          console.log(error);
          if (error.response.data) {
            setError("Error! " + error.response.data);
            setTimeout(() => {
                setError("");
            }, 2000);
          } else {
            setError("Something went wrong, try again later!");
            setTimeout(() => {
                setError("");
            }, 2000);
          }
        }
      };
    
  return (
    <div className="m-4 md:m-10 mt-24 p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
        {success && (
        <div className="alert alert-success d-flex align-items-center" role="alert">
            <i className="fal fa-check pr-10 text-bold" style={{ fontWeight: "bold" }}></i>
            <div>{success}</div>
        </div>
        )}
        {error && (
        <div className="alert alert-warning d-flex align-items-center" role="alert">
            <i className="fal fa-lock pr-10 text-bold" style={{ fontWeight: "bold" }}></i>
            <div>{error}</div>
        </div>
        )}
        <button onClick={goBack} className="inline-flex justify-center py-2 px-6 mb-2 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-800 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><i class="fad fa-arrow-left"></i></button>
        <button onClick={() => validateHandler(transaction?._id)} className="focus:outline-none mx-2 rounded-full text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-6 py-2.5 hover:drop-shadow-xl float-right">3. Validate KYC</button>
        <button onClick={() => anchorHandler(transaction?._id)} className="focus:outline-none mx-2 rounded-full text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-6 py-2.5 hover:drop-shadow-xl float-right">2. Create Anchor Account</button>
        <button onClick={() => verifyHandler(transaction?._id)} className="focus:outline-none mx-2 rounded-full text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-6 py-2.5 hover:drop-shadow-xl float-right">1. Verify Details</button>
        <Header category="Onboarding" title={transaction?.school_name} />
        {loading ? (
            <div className="py-4 overflow-scroll md:overflow-hidden flex justify-center items-center h-full">
                <div className="max-w-xl mx-auto mt-3 divide-y">
                    <div className="grid grid-cols-1 gap-6">
                        <p className="block text-center">
                            <ImSpinner className="animate-spin text-4xl text-gray-400" />
                        </p>
                    </div>
                </div>
            </div>
        ) : (
        <div className="grid grid-cols-3 gap-2 mb-2 m-2 mt-10">
            <div className="grid col-span-2 bg-white border dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
                <div>
                    <p aria-current="true" className="block font-semibold w-full px-4 py-2 rounded-lg text-white bg-indigo-700 border-b border-gray-200 cursor-pointer dark:bg-gray-800 dark:border-gray-600">
                         Business Information
                    </p>
                </div>
                <div className="mt-4">
                    <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-xs text-gray-500 truncate dark:text-gray-400">
                                        School ID
                                    </p>
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {transaction?._id}
                                    </p>
                                </div>
                                <div className="flex-1 min-w-0 items-center">
                                    <p className="text-xs text-gray-500 truncate dark:text-gray-400">
                                        Business Phone
                                    </p>
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {transaction?.phone_number}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                <p className="text-xs text-gray-500 truncate dark:text-gray-400">
                                        Business BVN
                                    </p>
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {transaction?.bvn}
                                    </p>
                                </div>
                                <div className="flex-1 min-w-0 items-center">
                                    <p className="text-xs text-gray-500 truncate dark:text-gray-400">
                                        Registration Type
                                    </p>
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {transaction?.registration_type}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-xs text-gray-500 truncate dark:text-gray-400">
                                        Business Category
                                    </p>
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {transaction?.industry}
                                    </p>
                                </div>
                                <div className="flex-1 min-w-0 items-center">
                                    <p className="text-xs text-gray-500 truncate dark:text-gray-400">
                                        Business Email
                                    </p>
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {transaction?.school_email	}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0 truncate" style={{ width: "50px" }}>
                                    <p className="text-xs text-gray-500 truncate dark:text-gray-400">
                                        About your School
                                    </p>
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {transaction?.description}
                                    </p>
                                </div>
                                <div className="flex-1 min-w-0 items-center">
                                    <p className="text-xs text-gray-500 truncate dark:text-gray-400">
                                        Date of Registration
                                    </p>
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {new Date(transaction?.date_of_registration).toLocaleDateString("en-NG")}
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <p aria-current="true" className="block font-semibold w-full px-4 py-2 my-2 rounded-lg text-white bg-indigo-700 border-b border-gray-200 cursor-pointer dark:bg-gray-800 dark:border-gray-600">
                        Business Addresses
                    </p>
                    <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-xs text-gray-500 truncate dark:text-gray-400">
                                        Register Address
                                    </p>
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {transaction?.address?.registered.address_line}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        {transaction?.address?.registered.city}
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                    {transaction?.address?.registered.state}
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-xs text-gray-500 truncate dark:text-gray-400">
                                        Operating Address
                                    </p>
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {transaction?.address?.main.address_line}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        {transaction?.address?.main.city}
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                    {transaction?.address?.main.state}
                                </div>
                            </div>
                        </li>
                    </ul>
                    <p aria-current="true" className="block font-semibold w-full px-4 py-2 my-2 rounded-lg text-white bg-indigo-700 border-b border-gray-200 cursor-pointer dark:bg-gray-800 dark:border-gray-600">
                        Business Officers
                    </p>
                    {<ul className="divide-y divide-gray-200 dark:divide-gray-700">
                        {transaction?.officials?.map((person) => (
                            <li key={person.phone_number} className="py-3 sm:py-4">
                                <p className="text-xs font-semibold text-gray-500 truncate dark:text-gray-400">
                                        {person.role}
                                    </p>
                                <div className="flex items-center space-x-4">
                                    <div className="flex-1 min-w-0">
                                        <p className="text-base text-gray-900 truncate dark:text-white">
                                            <span className='font-semibold'>Name: </span>{person.first_name} {person.last_name}
                                        </p>
                                        <p className="text-sm text-gray-900 truncate dark:text-gray-400">
                                            <span className='font-semibold'>Title: </span>{person.title}
                                        </p>
                                        <p className="text-sm text-gray-900 truncate dark:text-gray-400">
                                            <span className='font-semibold'>Percentage Owned: </span>{person.percentage}%
                                        </p>
                                        <p className="text-sm text-gray-900 truncate dark:text-gray-400">
                                            <span className='font-semibold'>BVN: </span>{person.bvn}
                                        </p>
                                        <p className="text-sm text-gray-900 truncate dark:text-gray-400">
                                            <span className='font-semibold'>Email: </span>{person.email}
                                        </p>
                                        <p className="text-sm text-gray-900 truncate dark:text-gray-400">
                                            <span className='font-semibold'>Phone: </span>{person.phone_number}
                                        </p>
                                    </div>
                                    <div className="flex-1 min-w-0">
                                        <p className="text-sm text-gray-900 truncate dark:text-gray-400">
                                            <span className='font-semibold'>DOB: </span>{new Date(person.date_of_birth).toLocaleDateString("en-NG")}
                                        </p>
                                        <p className="text-sm text-gray-900 truncate dark:text-white">
                                            <span className='font-semibold'>Nationality: </span>{person.nationality}
                                        </p>
                                        <p className="text-sm text-gray-900 truncate dark:text-white">
                                            <span className='font-semibold'>Address: </span>{person.address.address_line}
                                        </p>
                                        <p className="text-sm text-gray-900 truncate dark:text-white">
                                            <span className='font-semibold'>Location: </span>{person.address.city}, {person.address.state}
                                        </p>
                                    </div>
                                    
                                </div>
                            </li>
                        ))}
                    </ul>}
                </div>
            </div>
    
            <div className="grid bg-white h-40 dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
                <div className="">
                    <p aria-current="true" className="block font-semibold w-full px-4 py-2 rounded-lg text-white bg-indigo-700 border-b border-gray-200 cursor-pointer dark:bg-gray-800 dark:border-gray-600">
                        Documents
                    </p>
                </div>
                <div className="mt-4">
                    <ul className="text-sm font-medium text-gray-900 bg-white border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                        {transaction?.kyc_required_docs?.data?.map((doc) => (
                        <li className="block w-full flex justify-between py-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                            <span>{doc.type.replace(/_/g, ' ')}</span> <button style={doc?.submitted?.status  ? { background: 'rgb(139, 231, 139)' } : { background: 'rgb(227, 160, 8)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-sm">{doc?.submitted?.status ? ("Submitted") : ("Pending")}</button>
                        </li>
                        ))}
                    </ul>
                    <div className="flex justify-between mt-4 w-full">
                        <div className="flex gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">KYC Status</p>
                                <button style={transaction?.kyc_status === "approved" ? { background: 'rgb(139, 231, 139)' } : transaction?.kyc_status === "pending" ? { background: 'rgb(227, 160, 8)' } : { background: 'rgb(227, 79, 79)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-sm">{transaction?.kyc_status === "approved" ? ("Approved") : transaction?.kyc_status === "pending" ? ("Pending") : ("Not Started")}</button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between mt-4 w-full">
                        <div className="flex gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">KYC Progress</p>
                                <p className="text-md font-semibold">{kycProgressMapping[transaction?.kyc_progress]}</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between mt-4 w-full">
                        <div className="gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">Date Submitted</p>
                                <p className="text-md font-semibold">{(new Date(transaction?.createdAt)).toLocaleDateString('en-NG')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )}
    </div>
  )
}

export default ViewCustomer
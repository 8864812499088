import React, { useState, useEffect } from "react";
import { Header } from "../components";
import { useLocation } from "react-router-dom";
import { userRequest } from "../utils/requestMethods";
//import { useStateContext } from '../contexts/ContextProvider';
import {
  Information,
  Cashflow,
  Income,
  Spend,
  Behavioral,
  Transaction,
} from "../components";

const Viewloan = ({ history }) => {
  const location = useLocation();
  const userId = location.pathname.split("/")[2];
  const loanRef = location.pathname.split("/")[3];
  const [openTab, setOpenTab] = React.useState(1);
  const [loanInfo, setLoanInfo] = useState();
  const [mlsInfo, setMlsInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const { data } = await userRequest.get(`loans/${userId}/${loanRef}`);
        //console.log(data);
        setLoanInfo(data);
        setMlsInfo(data.mls);
        setUserInfo(data?.user_details.data);
        console.log(data, "user details");
      } catch (error) {
        console.log(error);
      }
    };
    fetchTransactions();
  }, [userId, loanRef]);

  const statusHandler = async (statusValue) => {
    setLoading(true);
  
    try {
      const updateData = {
        approved_wards: loanInfo.choose_wards,
        ...(statusValue === "full" || statusValue === "rejected"
          ? { status: statusValue, credit_decision: "approved" }
          : { credit_decision: statusValue }),
      };
  
      const { data } = await userRequest.put(`loans/${loanInfo.loanReference}`, updateData);
      console.log(data);
  
      handleStatusMessages(statusValue);
    } catch (error) {
      console.error(error);
      setErrorMessage("Something went wrong, try again later!");
    } finally {
      setLoading(false);
    }
  };
  
  const handleStatusMessages = (statusValue) => {
    const messages = {
      full: "Application Status has been set to Approved!",
      rejected: "Application Status has been set to Rejected!",
      approved: "Application Status has been set to Qualified!",
      declined: "Application Status has been set to Not Qualified!",
    };
  
    if (messages[statusValue]) {
      if (statusValue === "full" || statusValue === "approved") {
        setSuccess(messages[statusValue]);
        clearMessage(setSuccess);
      } else {
        setError(messages[statusValue]);
        clearMessage(setError);
      }
    }
  };
  
  const clearMessage = (setter) => {
    setTimeout(() => setter(""), 3000);
  };
  
  const setErrorMessage = (message) => {
    setError(message);
    clearMessage(setError);
  };
  

  return (
    <>
      <div className="m-3 mt-4 p-2 md:p-10 bg-white rounded-2xl dark:bg-secondary-dark-bg">
        {success && (
          <div
            className="alert alert-success d-flex align-items-center"
            role="alert"
          >
            <i
              className="fal fa-check pr-10 text-bold"
              style={{ fontWeight: "bold" }}
            ></i>
            <div>{success}</div>
          </div>
        )}
        {error && (
          <div
            className="alert alert-warning d-flex align-items-center"
            role="alert"
          >
            <i
              className="fal fa-lock pr-10 text-bold"
              style={{ fontWeight: "bold" }}
            ></i>
            <div>{error}</div>
          </div>
        )}
        <button
          type="button"
          onClick={() => statusHandler("declined")}
          disabled={loading}
          style={{ color: "white", backgroundColor: "rgb(228, 106, 118)" }}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 float-right"
        >
          Not Qualified
        </button>
        <button
          type="button"
          onClick={() => statusHandler("rejected")}
          disabled={loading}
          style={{ color: "white", backgroundColor: "rgb(228, 106, 118)" }}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 float-right"
        >
          Reject Loan
        </button>
        <button
          type="button"
          onClick={() => statusHandler("approved")}
          disabled={loading}
          style={{ backgroundColor: "blue" }}
          className="inline-flex mx-2 justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-blue-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right"
        >
          Qualified
        </button>
        <button
          type="button"
          onClick={() => statusHandler("full")}
          disabled={loading}
          style={{ backgroundColor: "blue" }}
          className="inline-flex mx-2 justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right"
        >
          Approve Loan
        </button>
        <p className="inline-flex p-2 text-lg font-extrabold tracking-tight text-slate-900 dark:text-gray-200 float-right">
          BVN: {userInfo?.bvn}
        </p>
        <Header category="Analysis" title={"User ID: " + loanInfo?.user} />
        <ul
          className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row sm:pt-10"
          role="tablist"
        >
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 6
                  ? "text-white bg-blue-600"
                  : "text-gray-700 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(6);
              }}
              data-toggle="tab"
              href="#link6"
              role="tablist"
            >
              User Info
            </a>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 1
                  ? "text-white bg-blue-600"
                  : "text-gray-700 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(1);
              }}
              data-toggle="tab"
              href="#link1"
              role="tablist"
            >
              Cash Flow
            </a>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 2
                  ? "text-white bg-blue-600"
                  : "text-gray-700 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
            >
              Income
            </a>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 3
                  ? "text-white bg-blue-600"
                  : "text-gray-700 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(3);
              }}
              data-toggle="tab"
              href="#link3"
              role="tablist"
            >
              Spend
            </a>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 4
                  ? "text-white bg-blue-600"
                  : "text-gray-700 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(4);
              }}
              data-toggle="tab"
              href="#link4"
              role="tablist"
            >
              Behavioral
            </a>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 5
                  ? "text-white bg-blue-600"
                  : "text-gray-700 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(5);
              }}
              data-toggle="tab"
              href="#link5"
              role="tablist"
            >
              Transaction Pattern
            </a>
          </li>
        </ul>
        <div className="px-2 py-5 flex-auto">
          <div className="tab-content tab-space">
            <div className={openTab === 1 ? "block" : "hidden"} id="link1">
              <Cashflow mls={mlsInfo} />
            </div>
            <div className={openTab === 2 ? "block" : "hidden"} id="link2">
              <Income mls={mlsInfo} />
            </div>
            <div className={openTab === 3 ? "block" : "hidden"} id="link3">
              <Spend mls={mlsInfo} />
            </div>
            <div className={openTab === 4 ? "block" : "hidden"} id="link4">
              <Behavioral mls={mlsInfo} />
            </div>
            <div className={openTab === 5 ? "block" : "hidden"} id="link5">
              <Transaction mls={mlsInfo} />
            </div>
            <div className={openTab === 6 ? "block" : "hidden"} id="link6">
              <Information info={userInfo} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Viewloan;

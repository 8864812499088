/* Create our Currency formatter.
export const formatter = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 0,
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as ₦2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as ₦2,501)
  });*/


  export const customFormatter = (value) => {
    // Check if the value is empty, null, or undefined
    if (value === null || value === undefined || value === '') {
      return '₦ 0.0'; // Return '₦0' for empty, null, or undefined values
    }
  
    // If the value is not empty, null, or undefined, format it using the original formatter
    const formatter = new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      minimumFractionDigits: 0,
    });
  
    return formatter.format(value); // Format the value using the original formatter
  };
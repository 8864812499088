import React, { createContext, useContext, useEffect, useState } from 'react';
//import axios from "axios";

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children, }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState('#4543b5');
  const [currentMode, setCurrentMode] = useState('Light');
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [user, setUser] = useState();
  //const [school, setSchool] = useState();
  
  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem('themeMode', e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem('colorMode', color);
  };

  const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true });

  useEffect(() => {
    if (localStorage.getItem("tokenWithUser")) {
      const tokenWithUser = JSON.parse(localStorage.getItem("tokenWithUser"));
      setUser(tokenWithUser);
    }
  }, []);

  /*useEffect(() => {
    const fetchSchoolInfo = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          'X-Requested-With': 'XMLHttpRequest',
          "x-access-token": ` ${user?.access_token}`,
        },
      };

      try {
        const { data } = await axios.get(
          `https://acceedeapi.herokuapp.com/schools/${user?._id}/all`,
          config
        );
        //console.log(JSON.stringify(data.schools[0]));
        setSchool(data.schools[0]);
      } catch (error) {
        console.log(error);
      }
    };

    fetchSchoolInfo();
  }, [user?.access_token, user?._id]);*/

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider value={{ user, currentColor, currentMode, activeMenu, screenSize, setScreenSize, handleClick, isClicked, initialState, setIsClicked, setActiveMenu, setCurrentColor, setCurrentMode, setMode, setColor, themeSettings, setThemeSettings }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);

import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { userRequest, formatter } from "../utils/requestMethods";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { ImSpinner } from "react-icons/im";

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "rgb(248, 250, 252)",
    },
  },
};

const PendingLoans = () => {
  const { user, currentColor } = useStateContext();
  const [transactions, setTransactions] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  //const userId = user?._id;
  //const schoolId = school?._id;
  //console.log(accessCode);
  //console.log(userId);
  /*console.log(schoolId);
  const start = new Date(startDate)?.toISOString().slice(0, 10);
  const end = new Date(endDate)?.toISOString().slice(0, 10);
  console.log(start + " " + end);*/

  const fetchTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await userRequest.get(`loans?status=pending&limit=200&sort_by=date&order=des`);
      setTransactions(data.loansAndRecova);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  //Do search
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    const start = new Date(startDate).toISOString().slice(0, 10);
    const end = new Date(endDate).toISOString().slice(0, 10);

    try {
      const { data } = await userRequest.get(
        `loans?status=pending&limit=200&sort_by=date&order=des&from=${start}&to=${end}`
      );
      //console.log(data);
      setTransactions(data.loansAndRecova);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const columns = [
    {
      name: "Customer ID",
      selector: (row) => "CUS-" + row?.user?._id,
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row?.user?.firstname + " " + row?.user?.lastname,
    },
    {
      name: "Customer Email",
      selector: (row) => row?.user?.email,
    },
    {
      name: "Requested Amount",
      selector: (row) => formatter.format(row.total_wards_fee),
    },
    {
      name: "Repayment Amount",
      selector: (row) => formatter.format(row.amount_to_payback),
    },
    {
      name: "Method",
      cell: (row) => (
        <span className="py-1 px-2 capitalize rounded-2xl text-md font-bold">
          {row?.method}
        </span>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <button
          style={{ background: "#F4E82F" }}
          className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold"
        >
          {row.status}
        </button>
      ),
    },
    {
      name: "Date",
      selector: (row) => new Date(row.createdAt).toLocaleDateString("en-NG"),
      sortable: true,
    },
    {
      cell: (row) => (
        <Link
          to={`/view-loan/${row?.user?._id}/${row.loanReference}`}
          className="text-md bg-indigo-300 rounded-full p-3 hover:bg-indigo-700 hover:drop-shadow-xl"
        >
          <MdKeyboardArrowRight />
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="m-3 mt-4 p-2 md:p-10 bg-white rounded-2xl dark:bg-secondary-dark-bg">
        <button
          type="button"
          onClick={() => setClicked(!clicked)}
          style={{ backgroundColor: currentColor }}
          className="mb-10 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right"
        >
          Filters
        </button>
        <Header category="Analysis" title="Statement Analysis" />
      
        <div className="py-4 overflow-scroll md:overflow-hidden">
            {clicked ? (
            <div className="grid gap-6 mb-6 md:grid-cols-4">
                <div>
                <label
                    htmlFor="startDate"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                    Start Date
                </label>
                <input
                    type="date"
                    id="startDate"
                    onChange={(e) => setStartDate(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                />
                </div>
                <div>
                <label
                    htmlFor="endDate"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                    End Date
                </label>
                <input
                    type="date"
                    id="endDate"
                    onChange={(e) => setEndDate(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                />
                </div>
                <div className="mt-6">
                <button
                    type="submit"
                    disabled={loading}
                    onClick={submitHandler}
                    className="py-2 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 disabled:bg-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Search
                </button>
                </div>
            </div>
            ) : null}
            <DataTable
            data={transactions}
            columns={columns}
            getRowId={(row) => row._id}
            pagination
            customStyles={customStyles}
            progressPending={loading}
            progressComponent={
                <ImSpinner className="animate-spin text-4xl text-gray-400" />
            }
            />
        </div>
    </div>
  );
};
export default PendingLoans;

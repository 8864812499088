import React from 'react';
//import { Link } from 'react-router-dom';
//import { formatter } from '../../utils/requestMethods';
import { BsCurrencyDollar } from 'react-icons/bs';
import { customFormatter } from '../../utils/currencyUtils';
import { getDifferenceBetweenDates, getMonth } from '../../utils/dateFormat';

const Income = ({ mls }) => {
    const currentColor = "#03C9D7";
    const currentBg = "#E5FAFB";
    return (
        <div>
            <div className="flex flex-wrap lg:flex-nowrap justify-center">
                <div className="bg-gray dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl wd-41 md:wd-41 p-8 pt-9 m-1" style={{ border: '1px solid #ebecf0' }}>
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-xl font-bold">Confidence Intervals on Salary</p>
                            <p className="text-xl font-bold">Statement Period: <span className="text-gray-500">{getMonth(new Date(mls?.cash_flow?.opening_date))} - {getMonth(new Date(mls?.cash_flow?.closing_date))}</span></p>
                            <p className="text-lg">First Day in Statement: <span className="text-gray-400">{mls?.cash_flow?.opening_date}</span></p>
                            <p className="text-lg">Last Day in Statement: <span className="text-gray-400">{mls?.cash_flow?.closing_date}</span></p>
                            <p className="text-lg"> Statement Duration: <span className="text-gray-400">{getDifferenceBetweenDates(mls?.cash_flow?.opening_date, mls?.cash_flow?.closing_date)}</span></p>
                        </div>
                        <button type="button" style={{ backgroundColor: currentColor }} className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4">
                            <BsCurrencyDollar />
                        </button>
                    </div>
                </div>
                {/* start cards one */}
                <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full md:w-60 p-4 pt-9 m-1" style={{ border: '1px solid #ebecf0', width: '18rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.income?.last_salary_date}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Last Salary Date</p>
                </div>
                {/* end cards one */}
                {/* start cards two */}
                <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full md:w-60 p-4 pt-9 m-1" style={{ border: '1px solid #ebecf0', width: '18rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{customFormatter(mls?.income?.average_other_income)}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Average of Other Income</p>
                </div>
            </div>
            {/* breakdown info cards */}
            <div className="flex m-3 flex-wrap gap-2 items-left">
                {/* start cards one */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.income?.salary_frequency}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Salary Frequency</p>
                </div>
                {/* end cards one */}
                {/* start cards two */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.income?.Number_salary_payments}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Number of Salary Payments</p>
                </div>
                {/* end cards two */}
                {/* start cards three */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.income?.Number_other_payments}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Number of Other Income Payments</p>
                </div>
                {/* end cards three */}
                {/* start cards four */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{customFormatter(mls?.income?.average_salary)}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Average Salary</p>
                </div>
                {/* end cards four */}
                {/* start cards one */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.income?.expected_salary_day}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Expected Salary Day</p>
                </div>
                {/* end cards one */}
                {/* start cards one */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.income?.last_salary_date}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Last Salary Date</p>
                </div>
                {/* end cards one */}
                {/* start cards two */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.income?.salary_earner}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Salary Earner</p>
                </div>
                {/* end cards two */}
                {/* start cards three */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{customFormatter(mls?.income?.median_income)}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Median Income</p>
                </div>
                {/* end cards three */}
                {/* start cards four }
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">Yes</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Gig Worker</p>
                </div>
                {/* end cards four */}
            </div>
        </div>
    )
}

export default Income

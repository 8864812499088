import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Header } from '../components';
import { userRequest } from '../utils/requestMethods';
import { useStateContext } from '../contexts/ContextProvider';

const Compliance = () => {
    const { user } = useStateContext();
    const userId = user?._id;

    const [users, setUsers] = useState({});

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const { data } = await userRequest.get(`/users/${userId}`);
                console.log(data);
                setUsers(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchUser();
    }, [userId]);

    return (
        <div className="m-3 mt-4 p-2 md:p-10 bg-white rounded-2xl dark:bg-secondary-dark-bg">
            <Header category="Profile" title="Personal Information" />
            <div className="mt-2 max-w-full divide-y">
                <div className="py-2">
                    <h3 className="text-1xl font-bold dark:text-gray-200">Contact Information</h3>
                    <hr />
                </div>
            </div>
            <div className="py-2 items-right">
                <div className="mt-2 max-w-full">
                    <div className="grid grid-cols-1 gap-6">
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">First Name</span>
                            <input type="text" defaultValue={users.firstname} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                        </label>
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Last Name</span>
                            <input type="text" defaultValue={users.lastname} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                        </label>
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Email Address</span>
                            <input type="email" defaultValue={users.email} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                        </label>
                        {/*<label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Date of Birth </span>
                            <input type="date" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"  />
                        </label>*/}
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Contact Person Phone</span>
                            <input type="tel" defaultValue={users.mobile_number} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                        </label>
                        <div className="px-4 py-3 text-right sm:px-6">
                            <Link to="/compliance/personal">
                            <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Update info</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Compliance;
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import axios from "axios";
import { Header, ButtonLoading } from '../components';
import { useStateContext } from '../contexts/ContextProvider';

const UpdateClass = ({ history }) => {
  const location = useLocation();
  const classId = location.pathname.split('/')[2];
  const name = location.pathname.split('/')[3];
  const level = location.pathname.split('/')[4];
  const price = location.pathname.split('/')[5];

  const { user, school } = useStateContext();
  const accessCode = user?.access_token;
  const schoolId = school?._id;

  //const [classes, setClasses] = useState({});
  const [class_name, setClassname] = useState("");
  const [class_price, setClassPrice] = useState("");
  const [level_of_education, setLevelOfEducation] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  /*useEffect(() => {
    const fetchClasses = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          'X-Requested-With': 'XMLHttpRequest',
          "x-access-token": ` ${accessCode}`,
        },
      };
      try {
        const { data } = await axios.get(
          `https://acceedeapi.herokuapp.com/classes?code=${schoolCode}`,
          config
        );
        //console.log(data.classes);
        setClasses(data.classes);
      } catch (error) {
        console.log(error);
      }
    };
    fetchClasses();
  }, [accessCode, schoolCode]);*/
  
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        'X-Requested-With': 'XMLHttpRequest',
        "x-access-token": ` ${accessCode}`,
      },
    };
    try {
      await axios.put(
        `https://acceede.herokuapp.com/classes/${schoolId}/${classId}`,
        { 
          schoolId,
          class_name, 
          class_price,
          level_of_education,
        },
        config
      );
      //console.log(data);
      history.push("/classes");
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
    setLoading(false);
  };
  return (
    <div className="m-2 md:m-10 mt-24  p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <Header category="Set Up" title="Update Class" />
      <div className="py-2 items-right">
        <div className="mt-2 max-w-full">
          <form onSubmit={submitHandler}>
            <div className="grid grid-cols-1 gap-6">
              {error &&
                <div className="alert alert-warning d-flex align-items-center" role="alert">
                  <i className="fal fa-lock pr-10"></i>
                  <div>
                    {error}
                  </div>
                </div>
              }
              <label className="block">
                <span className="text-gray-700">Name of Class <span style={{ color: 'green'}}>({name})</span></span>
                <select onChange={(e) => setClassname(e.target.value)} required className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                  <option value="">Select</option>
                  <option value="Pre-School">Pre-School</option>
                  <option value="KG 1">KG 1</option>
                  <option value="KG 2">KG 2</option>
                  <option value="KG 3">KG 3</option>
                  <option value="Nursery 1">Nursery 1</option>
                  <option value="Nursery 2">Nursery 2</option>
                  <option value="Nursery 3">Nursery 3</option>
                  <option value="Primary 1">Primary 1</option>
                  <option value="Primary 2">Primary 2</option>
                  <option value="Primary 3">Primary 3</option>
                  <option value="Primary 4">Primary 4</option>
                  <option value="Primary 5">Primary 5</option>
                  <option value="JSS 1">JSS 1</option>
                  <option value="JSS 2">JSS 2</option>
                  <option value="JSS 3">JSS 3</option>
                  <option value="SS 1">SS 1</option>
                  <option value="SS 2">SS 2</option>
                  <option value="SS 3">SS 3</option>
                </select>
              </label>
              <label className="block">
                <span className="text-gray-700 dark:text-gray-200">School Fees Amount <span style={{ color: 'green'}}>({price})</span></span>
                <input type="text" onChange={(e) => setClassPrice(e.target.value)} defaultValue={price} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"/>
              </label>
              <label className="block">
                <span className="text-gray-700">Level of Education <span style={{ color: 'green'}}>({level})</span></span>
                <select onChange={(e) => setLevelOfEducation(e.target.value)} required className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                  <option value="">Select</option>
                  <option value="pre_school">Pre-School</option>
                  <option value="kindergarten">Kindergarten</option>
                  <option value="nursery">Nursery</option>
                  <option value="primary">Primary</option>
                  <option value="secondary">Secondary</option>
                </select>
              </label>
              <div className="px-4 py-3 text-right sm:px-6"> 
                {loading ?
                  (
                    <ButtonLoading styles="inline-flex justify-center py-3 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600" />
                  ) : (
                    <button type="submit" className="inline-flex justify-center py-3 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Update Class</button>
                  )
                }
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default UpdateClass;

import React, { useCallback, useState } from "react";
import { Header } from "../components";
import DataTable from "react-data-table-component";
import { useStateContext } from "../contexts/ContextProvider";
import { formatter, userRequest } from "../utils/requestMethods";
import { ImSpinner } from "react-icons/im";
import { BiErrorCircle } from "react-icons/bi";
import { Modal, ModalBody } from "../components/Modal";
import { useParams } from "react-router-dom";
import { parseAPIFormatToDate } from "../utils/dateFormat";

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "rgb(248, 250, 252)",
    },
  },
};

const ViewMandate = () => {
  const { currentColor } = useStateContext();
  const [modal, setModal] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [stoppingMandate, setStoppingMandate] = useState(false);
  const [mandateHistory, setMandateHistory] = useState();

  const { mandateRef, customerId, authorisationCode } = useParams();

  const fetchMandateHistory = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await userRequest.post(
        `inflight/loan-repayment-history`,
        {
          mandateRef,
          customerId,
          authorisationCode,
          MERCHANT_ID: "27768931",
          REQUEST_ID: "23456644",
          API_TOKEN:
            "SGlQekNzMEdMbjhlRUZsUzJCWk5saDB6SU14Zk15djR4WmkxaUpDTll6bGIxRCs4UkVvaGhnPT0=",
          API_KEY: "Q1dHREVNTzEyMzR8Q1dHREVNTw==",
        }
      );
      setMandateHistory({ ...data.data, ...data.mandate });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [authorisationCode, customerId, mandateRef]);

  const handleStopMandate = async () => {
    try {
      setStoppingMandate(true);
      const { data } = await userRequest.post(`inflight/stop-collection`, {
        mandateRef,
        customerId,
        authorisationCode,
        MERCHANT_ID: "27768931",
        REQUEST_ID: "23456644",
        API_TOKEN:
          "SGlQekNzMEdMbjhlRUZsUzJCWk5saDB6SU14Zk15djR4WmkxaUpDTll6bGIxRCs4UkVvaGhnPT0=",
        API_KEY: "Q1dHREVNTzEyMzR8Q1dHREVNTw==",
      });

      toggleModal();
      fetchMandateHistory();
    } catch (error) {
      console.log(error);
    } finally {
      setStoppingMandate(false);
    }
  };

  React.useEffect(() => {
    fetchMandateHistory();
  }, [fetchMandateHistory]);

  const toggleModal = () => {
    setModal(!modal);
  };

  const columns = [
    {
      name: "Module Name",
      selector: "moduleName",
    },
    {
      name: "Net Salary",
      selector: (row) => formatter.format(row.netSalary),
    },
    {
      name: "Total Credit",
      selector: (row) => formatter.format(row.totalCredit),
    },
    {
      name: "Balance Due",
      selector: (row) => formatter.format(row.balanceDue),
    },
    {
      name: "Date",
      selector: (row) => new Date(row.createdAt).toLocaleDateString("en-NG"),
    },
  ];

  return (
    <div className="m-3 mt-4 p-2 md:p-10 bg-white rounded-2xl dark:bg-secondary-dark-bg">
      {loading ? (
        <div className="text-center">
          <ImSpinner className="animate-spin text-4xl text-gray-400 inline" />
        </div>
      ) : mandateHistory ? (
        <>
          <button
            type="button"
            onClick={toggleModal}
            style={{ backgroundColor: currentColor }}
            className={`mb-10 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right ${
              mandateHistory?.mandate?.stopped ? "hidden" : ""
            }`}
          >
            Stop Mandate
          </button>
          <Header category="Mandate" title={`Mandate ID: ${mandateRef}`} />
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 border p-4 rounded-xl">
            <div className="">
              <p className="text-md text-gray-400">Total Disbursed</p>
              <p className="text-lg font-bold tracking-tight">
                {formatter.format(mandateHistory?.totalDisbursed)}
              </p>
            </div>
            <div className="">
              <p className="text-md text-gray-400">Outstanding Loan Balance</p>
              <p className="text-lg font-bold tracking-tight">
                {formatter.format(mandateHistory?.outstandingLoanBal)}
              </p>
            </div>
            <div className="">
              <p className="text-md text-gray-400">Disbursement Date</p>
              <p className="text-lg font-bold tracking-tight">
                {parseAPIFormatToDate(
                  mandateHistory?.dateOfDisbursement
                ).toLocaleDateString("en-NG", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </p>
            </div>
            <div className="">
              <p className="text-md text-gray-400">Collection Start Date</p>
              <p className="text-lg font-bold tracking-tight">
                {parseAPIFormatToDate(
                  mandateHistory?.collectionStartDate
                ).toLocaleDateString("en-NG", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            </div>
            <div className="">
              <p className="text-md text-gray-400">Loan Repayment Ref</p>
              <p className="text-lg font-bold tracking-tight">
                {mandateHistory?.loanRepaymentRef ?? "N/A"}
              </p>
            </div>
          </div>
          <div className="py-4 overflow-scroll md:overflow-hidden">
            <DataTable
              data={mandateHistory?.repayment ?? []}
              columns={columns}
              getRowId={(row) => row._id}
              pagination
              customStyles={customStyles}
              progressPending={loading}
              progressComponent={
                <ImSpinner className="animate-spin text-4xl text-gray-400" />
              }
            />
          </div>
        </>
      ) : null}

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalBody>
          <div className="text-center">
            <BiErrorCircle
              size={40}
              className="text-red-700 text-center inline-block mb-3"
            />
            <p className="text-xl font-bold">
              Are you sure you want to stop this mandate?
            </p>
            <p className="text-md text-gray-400">
              This action cannot be undone
            </p>

            <div className="space-x-2">
              <button
                type="button"
                onClick={toggleModal}
                className="mt-10 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 bg-red-600 disabled:opacity-70 disabled:cursor-not-allowed"
                disabled={stoppingMandate}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleStopMandate}
                style={{ backgroundColor: currentColor }}
                className="mt-10 inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-70 disabled:cursor-not-allowed"
                disabled={stoppingMandate}
              >
                {stoppingMandate && (
                  <ImSpinner className="animate-spin text-sm text-white inline mr-1" />
                )}
                Stop Mandate
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ViewMandate;

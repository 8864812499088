import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { userRequest } from "../utils/requestMethods";
import { MdKeyboardArrowRight } from "react-icons/md";
import { ImSpinner } from "react-icons/im";

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "rgb(248, 250, 252)",
    },
  },
};

const Mandates = () => {
  const { user } = useStateContext();
  const [mandates, setMandates] = useState();
  //const [startDate, setStartDate] = useState(new Date());
  //const [endDate, setEndDate] = useState(new Date());
  const [clicked, setClicked] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [kycStatus, setKycStatus] = useState("");

  const fetchTransactions = useCallback(async () => {
    //console.log(kycStatus)
    try {
      setLoading(true);
      const { data } = await userRequest.get(`/schools?limit=500&kyc_status=${kycStatus}`);
      //console.log(data.schools);
      setMandates(data.schools);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [kycStatus]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions, refresh]);

  /*const verifyHandler = async (userId) => {
    try {
      const { data } = await userRequest.post(`/schools/${userId}/kyc-verify`, {
        status: "approved"
      });
      console.log(data);
      setRefresh(!refresh);
      setSuccess("KYC details validated successfully!");
      setTimeout(() => {
        setSuccess(false);
    }, 1000);
      //setRefresh(data._id);
    } catch (error) {
      console.log(error);
      if (error.response.data) {
        setError("Error! " + error.response.data);
        setTimeout(() => {
            setError("");
        }, 2000);
      } else {
        setError("Something went wrong, try again later!");
        setTimeout(() => {
            setError("");
        }, 2000);
      }
    }
  };

  const anchorHandler = async (userId) => {
    try {
      const { data } = await userRequest.post(`/schools/${userId}/create-account`, {});
      console.log(data);
      setRefresh(!refresh);
      setSuccess("Anchor customer Account created successfully!");
      setTimeout(() => {
        setSuccess(false);
    }, 1000);
      //setRefresh(data._id);
    } catch (error) {
      console.log(error);
      if (error.response.data) {
        setError("Error! " + error.response.data);
        setTimeout(() => {
            setError("");
        }, 2000);
      } else {
        setError("Something went wrong, try again later!");
        setTimeout(() => {
            setError("");
        }, 2000);
      }
    }
  };

  const validateHandler = async (userId) => {
    try {
      const { data } = await userRequest.post(`/schools/${userId}/validate-kyc`, {});
      console.log(data);
      setSuccess("KYC Validation successful!");
      setTimeout(() => {
        setSuccess(false);
    }, 1000);
      //setRefresh(data._id);
    } catch (error) {
      console.log(error);
      if (error.response.data) {
        setError("Error! " + error.response.data);
        setTimeout(() => {
            setError("");
        }, 2000);
      } else {
        setError("Something went wrong, try again later!");
        setTimeout(() => {
            setError("");
        }, 2000);
      }
    }
  };*/

  const kycProgressMapping = {
    0: 'Not Started',
    1: 'Details Submitted',
    2: 'Reviewed',
    3: 'Anchor Account Created',
    4: 'Validation In Progress',
    5: 'Awaiting Docs',
    6: 'KYC Validation Failed',
    7: 'KYC Validated',
    8: 'Deposit Account Created'
  };

  const columns = [
    {
      name: "Customer ID",
      selector: (row) => row._id,
    },
    {
      name: "School Name",
      selector: (row) => row.school_name,
    },
    {
      name: "KYC Status",
      selector: (row) => <button style={row.kyc_status === "approved" ? { background: 'rgb(139, 231, 139)' } : row.kyc_status === "pending" ? { background: 'rgb(227, 160, 8)' } : { background: 'rgb(227, 79, 79)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-sm">{row.kyc_status === "approved" ? ("Approved") : row.kyc_status === "pending" ? ("Pending") : ("Not Started")}</button>,
    },
    {
      name: "KYC Progress",
      selector: (row) => kycProgressMapping[row.kyc_progress],
    },
    /*{
        cell: (row) => <button onClick={() => verifyHandler(row._id)} className="focus:outline-none text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 py-2.5 hover:drop-shadow-xl">Verify</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
    {
        cell: (row) => <button onClick={() => anchorHandler(row._id)} className="focus:outline-none text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 py-2.5 hover:drop-shadow-xl">Anchor</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
    {
        cell: (row) => <button onClick={() => validateHandler(row._id)} className="focus:outline-none text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 py-2.5 hover:drop-shadow-xl">Validate</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },*/
    {
      cell: (row) => <Link to={`/customers/view-customer/${row?._id}`} className="text-md bg-indigo-300 rounded-full p-3 hover:bg-indigo-700 hover:drop-shadow-xl"><MdKeyboardArrowRight /></Link>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="m-3 mt-4 p-2 md:p-10 bg-white rounded-2xl dark:bg-secondary-dark-bg">
      <button
        type="button"
        onClick={() => setClicked(!clicked)}
        className="mb-10 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-800 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right"
      >
        Filters
      </button>
      <Header category="Onboarding" title={"Welcome, " + user?.firstname + "!"} />
      {/*<h1 className="text-3xl font-extrabold tracking-tight text-slate-900 pl-2 pt-4 dark:text-gray-200">
        Welcome, {user?.firstname}!
      </h1>
      <p className="tracking-tight text-slate-600 pl-2 dark:text-gray-200">
        In-depth analysis of customers financial transactions
      </p>*/}
      <div className="py-4 overflow-scroll md:overflow-hidden">
        {clicked ? (
          <div className="grid gap-6 mb-6 md:grid-cols-4">
            <div>
              <label
                htmlFor="startDate"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                KYC Status
              </label>
              <select id="kycStatus" onChange={(e) => setKycStatus(e.target.value)} className="bg-gray-100 border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                <option value="">Select Status</option>
                <option value="approved">Approved</option>
                <option value="pending">Pending</option>
                <option value="rejected">Rejected</option>
                <option value="not_started">Not Started</option>
              </select>
            </div>
            {/*<div>
              <label htmlFor="endDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Name of School
              </label>
              <input
                type="text"
                id="kycStatus"
                onChange={(e) => setKycStatus(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="mt-6">
              <button
                type="submit"
                disabled={loading}
                // onClick={submitHandler}
                className="py-2 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 disabled:bg-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Search
              </button>
            </div>*/}
          </div>
        ) : null}
        {success &&
          <div className="alert alert-success d-flex align-items-center" role="alert">
            <i className="fal fa-check pr-10"></i>
            <div>
              Success! {success}
            </div>
          </div>
        }
        {error &&
          <div className="alert alert-warning d-flex align-items-center" role="alert">
            <i className="fal fa-lock pr-10"></i>
            <div>
              Error Occurred: {error}
            </div>
          </div>
        }
        <DataTable
          data={mandates}
          columns={columns}
          getRowId={(row) => row._id}
          pagination
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={
            <ImSpinner className="animate-spin text-4xl text-gray-400" />
          }
        />
      </div>
    </div>
  );
};

export default Mandates;

import React from 'react';
//import { Link } from 'react-router-dom';
//import { formatter } from '../../utils/requestMethods';
import { BsCurrencyDollar } from 'react-icons/bs';
import { getDifferenceBetweenDates, getMonth } from '../../utils/dateFormat';

const Transaction = ({ mls }) => {
    //console.log(mls?.transaction_pattern);
    const currentColor = "#ffb836";
    const currentBg = "rgba(255, 184, 54, 0.21)";
    return (
        <div>
            <div className="flex flex-wrap lg:flex-nowrap justify-center">
                <div className="bg-gray dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl wd-41 md:wd-41 p-8 pt-9 m-1" style={{ border: '1px solid #ebecf0' }}>
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-xl font-bold">Statement Period: <span className="text-gray-500">{getMonth(new Date(mls?.cash_flow?.opening_date))} - {getMonth(new Date(mls?.cash_flow?.closing_date))}</span></p>
                            <p className="text-lg">First Day in Statement: <span className="text-gray-400">{mls?.cash_flow?.opening_date}</span></p>
                            <p className="text-lg">Last Day in Statement: <span className="text-gray-400">{mls?.cash_flow?.closing_date}</span></p>
                            <p className="text-lg"> Statement Duration: <span className="text-gray-400">{getDifferenceBetweenDates(mls?.cash_flow?.opening_date, mls?.cash_flow?.closing_date)}</span></p>
                        </div>
                        <button type="button" style={{ backgroundColor: currentColor }} className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4">
                            <BsCurrencyDollar />
                        </button>
                    </div>
                </div>
                {/* start cards one */}
                <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full md:w-60 p-4 pt-9 m-1" style={{ border: '1px solid #ebecf0', width: '18rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">N/A {mls?.transaction_pattern?.nodw_balance_less}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">N O D W Balance Less</p>
                </div>
                {/* end cards one */}
                {/* start cards two */}
                <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full md:w-60 p-4 pt-9 m-1" style={{ border: '1px solid #ebecf0', width: '18rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.transaction_pattern?.most_frequent_balance_range.join(', ')}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Most Frequent Balance Range</p>
                </div>
            </div>
            {/* breakdown info cards */}
            <div className="flex m-3 flex-wrap gap-2 items-left">
                {/* start cards one */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.transaction_pattern?.highest_mawo_credit}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Highest MAWO Credit</p>
                </div>
                {/* end cards one */}
                {/* start cards two */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.transaction_pattern?.transaction_btw_100000_500000}</span>
                    </p>
                    <p className="text-sm text-gray-400 mt-1">Transactions Between 100,000 and 500,000</p>
                </div>
                {/* end cards two */}
                {/* start cards three */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.transaction_pattern?.nodw_balance_less_than_5000}</span>
                    </p>
                    <p className="text-md text-gray-400 mt-1">NODW Balance Less than 5,000</p>
                </div>
                {/* end cards three */}
                {/* start cards four */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.transaction_pattern?.transactions_btw_10000_100000}</span>
                    </p>
                    <p className="text-sm text-gray-400 mt-1">Transactions Between 10,000 and 100,000</p>
                </div>
                {/* end cards four */}
                {/* start cards one */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.transaction_pattern?.transaction_less_than_10000}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Transactions Less than 10,000</p>
                </div>
                {/* end cards one */}
                {/* start cards two */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.transaction_pattern?.highest_mawo_debit}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Highest MAWO Debit</p>
                </div>
                {/* end cards two */}
                {/* start cards three */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.transaction_pattern?.maww_zero_balance}</span>
                    </p>
                    <p className="text-md text-gray-400 mt-1">MAWW Zero Balance In Account</p>
                </div>
                {/* end cards three */}
                {/* start cards four */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.transaction_pattern?.most_frequent_transaction_range.join(', ')}</span>
                    </p>
                    <p className="text-md text-gray-400 mt-1">Most Frequent Transaction Range</p>
                </div>
                {/* end cards four */}
                {/* start cards one */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.transaction_pattern?.last_date_of_debit}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Last Date Of Debit</p>
                </div>
                {/* end cards one */}
                {/* start cards two */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.transaction_pattern?.last_date_of_credit}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Last Date Of Credit</p>
                </div>
                {/* end cards two */}
                {/* start cards three */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.transaction_pattern?.transactions_greater_than_500000}</span>
                    </p>
                    <p className="text-md text-gray-400 mt-1">Transactions Greater than 500,000</p>
                </div>
                {/* end cards three */}
            </div>
        </div>
    )
}

export default Transaction

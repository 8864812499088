import React from 'react'
//import { Link } from 'react-router-dom';
//import { formatter } from '../../utils/requestMethods';
import { getMonth, getDifferenceBetweenDates } from '../../utils/dateFormat';
import { BsCurrencyDollar } from 'react-icons/bs';
import { customFormatter } from '../../utils/currencyUtils';

const Cashflow = ({ mls }) => {
    //console.log(mls?.cash_flow);
    const currentColor = "#1e4db7";
    const currentBg = "#91a6f5";
    return (
        <div>
            <div className="flex flex-wrap lg:flex-nowrap justify-center">
                <div className="bg-gray dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl wd-41 md:wd-41 p-8 pt-9 m-1" style={{ border: '1px solid #ebecf0' }}>
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-xl font-bold">Statement Period: <span className="text-gray-500">{getMonth(new Date(mls?.cash_flow?.opening_date))} - {getMonth(new Date(mls?.cash_flow?.closing_date))}</span></p>
                            <p className="text-lg">First Day in Statement: <span className="text-gray-400">{mls?.cash_flow?.opening_date}</span></p>
                            <p className="text-lg">Last Day in Statement: <span className="text-gray-400">{mls?.cash_flow?.closing_date}</span></p>
                            <p className="text-lg"> Statement Duration: <span className="text-gray-400">{getDifferenceBetweenDates(mls?.cash_flow?.opening_date, mls?.cash_flow?.closing_date)}</span></p>
                        </div>
                        <button type="button" style={{ backgroundColor: currentColor }} className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4">
                            <BsCurrencyDollar />
                        </button>
                    </div>
                </div>
                {/* start cards one */}
                <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full md:w-60 p-4 pt-9 m-1" style={{ border: '1px solid #ebecf0', width: '18rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{customFormatter(mls?.cash_flow?.closing_balance)}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Closing Balance</p>
                </div>
                {/* end cards one */}
                {/* start cards two */}
                <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full md:w-60 p-4 pt-9 m-1" style={{ border: '1px solid #ebecf0', width: '18rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{customFormatter(mls?.cash_flow?.opening_balance)}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Opening Balance</p>
                </div>
            </div>
            {/* breakdown info cards */}
            <div className="flex m-3 flex-wrap gap-2 items-left">
                {/* start cards one */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{customFormatter(mls?.cash_flow?.average_credits)}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Average Credits</p>
                </div>
                {/* end cards one */}
                {/* start cards two */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{mls?.cash_flow?.account_activity}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Account Activity</p>
                </div>
                {/* end cards two */}
                {/* start cards three */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{customFormatter(mls?.cash_flow?.average_balance)}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Average Balance</p>
                </div>
                {/* end cards three */}
                {/* start cards four */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{customFormatter(mls?.cash_flow?.total_credit_turnover)}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Total Credit Turnover</p>
                </div>
                {/* end cards four */}
                {/* start cards one */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{customFormatter(mls?.cash_flow?.average_debits)}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Average Debits</p>
                </div>
                {/* end cards one */}
                {/* start cards two */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{customFormatter(mls?.cash_flow?.total_debit_turnover)}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Total Debit Turnover</p>
                </div>
                {/* end cards two */}
                {/* start cards three */}
                <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-60 p-4 pt-9 rounded-2xl" style={{ border: '1px solid #ebecf0', width: '17.7rem' }}>
                    <button type="button" style={{ color: currentColor, backgroundColor: currentBg }} className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                        <BsCurrencyDollar />
                    </button>
                    <p className="mt-3">
                        <span className="text-lg font-semibold">{customFormatter(mls?.cash_flow?.max_monthly_repayment)}</span>
                    </p>
                    <p className="text-lg text-gray-400 mt-1">Max Monthly Repayment</p>
                </div>
                {/* end cards three */}
            </div>
        </div>
    )
}

export default Cashflow

import React from "react";

const Modal = ({
  isOpen,
  toggle,
  backdropToggle,
  title,
  children,
  showCloseButton,
}) => {
  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={(e) => {
            e.stopPropagation();
            if (e.target === e.currentTarget && backdropToggle && toggle) {
              toggle();
            }
          }}
        >
          {/* Modal content */}
          <div className="bg-white rounded-lg p-4 transform transition-all max-w-md w-full">
            {/* Modal close button */}
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-gray-700 text-lg capitalize">
                {title}
              </h3>
              {showCloseButton && (
                <button
                  className="text-gray-500 hover:text-gray-800"
                  onClick={toggle}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              )}
            </div>

            {/* Modal content */}
            {React.Children.map(children, (child) => {
              return React.cloneElement(child, {
                toggle,
              });
            })}
          </div>
        </div>
      )}
    </>
  );
};

const ModalBody = ({ children, className }) => {
  return (
    <>
      <div className={`p-4 ${className}`}>{children}</div>
    </>
  );
};

export { Modal, ModalBody };

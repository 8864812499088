import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { userRequest, formatter } from "../utils/requestMethods";

import { MdKeyboardArrowRight, MdOutlineInsertDriveFile } from "react-icons/md";
import { ImSpinner } from "react-icons/im";

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "rgb(248, 250, 252)",
    },
  },
};

const Mandates = () => {
  const { currentColor } = useStateContext();
  const [mandates, setMandates] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await userRequest.get(`inflight/mandates`);
      setMandates(data.mandates);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const columns = [
    {
      name: "Customer ID",
      selector: (row) => "CUS-" + row.user._id,
    },
    {
      name: "Customer Name",
      selector: (row) => row.user.firstname + " " + row.user.lastname,
    },
    {
      name: "Customer Email",
      selector: (row) => row.user.email,
    },
    {
      name: "Reference",
      selector: (row) => row?.mandate?.mandateReference,
    },
    {
      name: "Date",
      selector: (row) =>
        new Date(row?.mandate?.createdAt).toLocaleDateString("en-NG"),
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <Link
            to={`/view-mandate/${row?.mandate?.mandateReference}/${row.user_details.customerId}/${row.user_details.authorisationCode}`}
            className="text-md bg-indigo-300 rounded-full p-3 hover:bg-indigo-700 hover:drop-shadow-xl"
          >
            <MdKeyboardArrowRight />
          </Link>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="m-3 mt-4 p-2 md:p-10 bg-white rounded-2xl dark:bg-secondary-dark-bg">
      <button
        type="button"
        onClick={() => setClicked(!clicked)}
        style={{ backgroundColor: currentColor }}
        className="mb-10 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right"
      >
        Filters
      </button>
      <Header category="Inflights" title="All Mandates" />
      <div className="py-4 overflow-scroll md:overflow-hidden">
        {clicked ? (
          <div className="grid gap-6 mb-6 md:grid-cols-4">
            <div>
              <label
                htmlFor="startDate"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Start Date
              </label>
              <input
                type="date"
                id="startDate"
                onChange={(e) => setStartDate(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div>
              <label
                htmlFor="endDate"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                End Date
              </label>
              <input
                type="date"
                id="endDate"
                onChange={(e) => setEndDate(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="mt-6">
              <button
                type="submit"
                disabled={loading}
                // onClick={submitHandler}
                className="py-2 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 disabled:bg-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Search
              </button>
            </div>
          </div>
        ) : null}
        <DataTable
          data={mandates}
          columns={columns}
          getRowId={(row) => row._id}
          pagination
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={
            <ImSpinner className="animate-spin text-4xl text-gray-400" />
          }
        />
      </div>
    </div>
  );
};

export default Mandates;
